var apmplayerwindow

export default function openPlayer(url) {
  let playerParams = 'width=750,height=500,scrollbars=1'
	apmplayerwindow = window.open(url, 'apmplayer', playerParams)
  
	if (window.focus) {
		apmplayerwindow.focus()
	};
}
