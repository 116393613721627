export default function () {
  // audiostreams drawer
  $('a.morestreams').on('click', function(e) {
    e.preventDefault()

    $(this).hide()
    $('a.lessstreams').show()
    $('.audiostreams.morestreams').slideDown(300)
  })

  $('a.lessstreams').on('click', function(e) {
    e.preventDefault()

    $(this).hide()
    $('a.morestreams').show()
    $('.audiostreams.morestreams').slideUp(300)
  })
}
