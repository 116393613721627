/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import activateSocialFeeds from '../lib/social-feeds'
import adMover from '../lib/ad-mover'
import fccselect from '../lib/fccselect'
import determineScreenRes from '../lib/screen-res'
import fullWidthImgs from '../lib/full-width-imgs'
import loadJquery from '../lib/load-jquery'
import loadMemberTestimonials from '../lib/member-testimonials'
import moreStreams from '../lib/more-streams'
import openPlayer from '../lib/open-player'
import topNavSettings from '../lib/top-nav'
import { Player } from 'apm-html5-player';

loadJquery()

$(function () {
  determineScreenRes()
  topNavSettings()
  moreStreams()
  activateSocialFeeds()
  fullWidthImgs()
  loadMemberTestimonials()
  adMover()
  fccselect()
  window.openPlayer = openPlayer

  const playerElement = document.querySelector('.js-player');
  if(playerElement) {
    const player = new Player(playerElement);
    player.init()
  }

  // functions dependent on screen resolution
  $(window).resize(determineScreenRes)
  $(window).resize(fullWidthImgs)

  //check breakpoint on resize for lakana ads
  var breakpoint = 640;
  var initialWidth = window.innerWidth;

  $(window).resize(function () {
    var newWidth = window.innerWidth;

    if (initialWidth >= breakpoint && newWidth < breakpoint) {
      adMover()
    }

    if (initialWidth < breakpoint && newWidth >= breakpoint) {
      adMover()
    }
    initialWidth = newWidth;
  })


  var s=document.createElement('script');
  s.type='text/javascript';
  s.src='https://a.omappapi.com/app/js/api.min.js';
  s.async=true;
  s.dataset.user="108635";
  s.dataset.account="120426";
  document.getElementsByTagName('head')[0].appendChild(s);
})
