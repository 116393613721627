// inspired by AppendAround ( http://jsbin.com/oqacox/3 ) and MPRNews approach ( http://pastebin.com/M8RYbHgJ )
export default function adMover() {
  // wherever an ad should live, the parent div should have a unique data-set identifier tag
  $('div[data-set]').each(function() {
    var $ad = $(this)
    var att = 'data-set'
    var key = $ad.attr(att)
    var $set = $("["+ att +"='"+ $ad.closest("["+ att +"]").attr(att) +"']")
    // just moving the contents of the ad-containing div (header and googletag id)
    var $adGuts = $ad.children()

    if ( ($ad.is(':hidden')) && ($adGuts.length > 0) ) {
      $adGuts.appendTo($set.filter(':visible:eq(0)'))

      if (typeof googletag === 'undefined') {
        return;
      }

      // correcting for google tagmanager push weirdness
      if (typeof googletag === 'object') {
        googletag.pubads().disableInitialLoad([key]);
        googletag.cmd.push(function() {
          googletag.display(key)
        })
        googletag.pubads().refresh([key]);

      } else {
        setTimeout(function() {
          googletag.pubads().disableInitialLoad([key]);
          googletag.cmd.push(function() {
          googletag.display(key)
        })
          googletag.pubads().refresh([key]);
        }, 5000)
      }
    }
  })
}
