export default function fullwidthImgs() {
    // for images that are wider than their parent container, restrict their size to 100%
  var contentColWidth = $('div.mpr_content.cf').width()

  if ($('.primary_focus').length) {
    contentColWidth = $('.primary_focus').width()
  }

  $('.mpr_content img').each(function() {
    var imgWidth = $(this).width()

    if (imgWidth > contentColWidth) {
      $(this).width('100%')
    }
  })
}
