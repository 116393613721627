export default function fccselect() {
  $('#fccselect').bind('submit', function (e) {
    e.preventDefault();
    var $form = $(this),
      $select = $form.find('select'),
      fcclink = $select.val();
      window.open(fcclink);
    
  })
}

