
var testimonials = {
  "testimonials": [
    {
      "membername": "Elizabeth",
      "memberlocation": "St, Paul, MN",
      "memberphoto": "//publicradio1.wpengine.netdna-cdn.com/newscut/wp-content/themes/mpr-news-blogs/images/membership/elizabeth.png",
      "memberquote": "MPR is an important part of my commitment toward maintaining a healthy lifestyle."
    },
    {
      "membername": "Shelly",
      "memberlocation": "Blaine, MN",
      "memberphoto": "//publicradio1.wpengine.netdna-cdn.com/newscut/wp-content/themes/mpr-news-blogs/images/membership/shelly.png",
      "memberquote": "I love being part of such a gift to the community, free on their radio or computer, anytime!"
    },
    {
      "membername": "Ray",
      "memberlocation": "Rochester, MN",
      "memberphoto": "//publicradio1.wpengine.netdna-cdn.com/newscut/wp-content/themes/mpr-news-blogs/images/membership/ray.png",
      "memberquote": "MPR is there when I wake up, throughout the day, and every evening."
    },
    {
      "membername": "Ginger",
      "memberlocation": "Stillwater, MN",
      "memberphoto": "//publicradio1.wpengine.netdna-cdn.com/newscut/wp-content/themes/mpr-news-blogs/images/membership/ginger.png",
      "memberquote": "MPR is a constant in my life. I come to work informed and ready to go for the day."
    }
  ]
}

export default function loadMemberTestimonials() {
  var quotes = []
  $.each(testimonials.testimonials, function(key, val) {
    quotes.push("<blockquote class='testimonial' style='background-image: url(" + val.memberphoto + ")'>&ldquo" + val.memberquote + "&rdquo<cite>" + val.membername + " from " + val.memberlocation + "</cite></blockquote>")
  })

  var totalQuotes = quotes.length
  var randomnum = Math.floor(Math.random()*totalQuotes)
  var randomQuote = quotes[randomnum]

  $('.member_focus .quoteblock').append(randomQuote)
}
