import Worker from "worker-loader!./social-media-worker";
var feed;

export default function activateSocialFeeds() {
  initDOM();
  loadFbData();
}

function initDOM() {
  $(window).load(function() {
    feed = {
      links: $(".socialfeedlinks a"),
      facebook: $(".socialfeed .facebookfeed"),
      twitter: $(".socialfeed .twitterfeed")
    };

    feed.links.on("click", handleSocialFeedLinkClick);
    feed.twitter.show();
    feed.facebook.hide();
  });
}

function handleSocialFeedLinkClick(e) {
  e.preventDefault();

  if ($(this).hasClass("active")) return;

  feed.links.toggleClass("active");

  [feed.twitter, feed.facebook].map(function(network) {
    network.toggle();
  });
}

function loadFbData() {
  var worker = new Worker();
  worker.postMessage("fbFeedInit");
  worker.onmessage = appendPosts;
}

function appendPosts(event) {
  if (!event.data || !event.data.length) {
    return;
  }

  $("#fbPosts").html("");

  event.data.map(function(post) {
    $("#fbPosts").append(post);
  });
}
