import determineScreenRes from './screen-res'

export default function topNavSettings() {
  // 	moveDonateBox()
  var displayState = determineScreenRes()

  // if top nav has children links, add a 'toplevel' class to visible parent element
  $('nav ul > li').each(function() {
    var $this = $(this)
    if ($this.children('ul').length > 0) {
      $this.addClass('toplevel')
    }
  })

  // toggle top nav
  $('nav li.more a').on('click', function(e) {
    e.preventDefault()

    $('nav ul.more').toggle()

    if ($('nav ul.more').is(':visible')) {
      $(this).addClass('active')
    } else {
      $(this).removeClass('active')
    }
  })

  $('a.allnav').on('click', function(e) {
    e.preventDefault()
    var $this = $(this)
    if ($('.allmenus').is(':hidden')) {
      $this.find($('.opennav').hide())
      $this.find($('.closenav').show())
      $this.addClass('active')
    } else {
      $this.find($('.closenav').hide())
      $this.find($('.opennav').show())
      $this.removeClass('active')
    }

    $('.allmenus').toggle()
  })

  $('ul.more .toplevel > a').on('click', function(e) {
    e.preventDefault()
    var $this = $(this)
    var siblings = $this.siblings('ul')
    var topLevelUl = $('.toplevel > ul')
    var siblingsHidden = siblings.is(':hidden')
    // hide other open nav menus
    if (siblingsHidden && topLevelUl.is(':visible')) {
      $('a.active').removeClass('active')
      topLevelUl.hide()
    }

    siblings.toggle()

    if ($this.hasClass('active') && !siblingsHidden) {
      $this.removeClass('active')
    } else {
      $this.addClass('active')
    }
  }
  )
}

function moveDonateBox() {
  if (displayState == 'mobile') {
    $('nav p.donate')
      .removeClass('right')
      .addClass('left')
      .appendTo('.mobileConnect')
  }
}
